trigger = document.querySelector("#trigger");
navigation = document.querySelector("#navigation");
trigger.addEventListener("click", (event) => {
    
    event.currentTarget.classList.toggle("active")
    navigation.classList.toggle("active")
    
})

const img = document.querySelectorAll(".works_photo img");
if(img.length > 0){
    
    img.forEach(item => {
        
        item.addEventListener('load', e => {
            console.log(item);
            const w = item.width;
            const h = item.height;
            
            if(w <= h){
                item.classList.add("_vertical");
            }
        })
    })
}

const diaryImg = document.querySelectorAll(".diary_article_main img");
if(diaryImg.length > 0){
    diaryImg.forEach(item => {
        const w = item.width;
        const h = item.height;
        
        if(w <= h){
            item.classList.add("_vertical");
        }
    })
}
